import React from 'react'

function NavBar() {
    return (
        <nav id="navBar">
            <div id="menuContainer">
                {/* <p>Musik</p>
                <p>Nyheter</p>
                <p>Press</p> */}
            </div>
        </nav>
    )
}

export default NavBar
