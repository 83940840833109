import React from 'react'
import speaker from './speaker.png'

function Playlist(props) {
    function clickedSong(){
        console.log("HEj")
        props.setSongToPlay()
    }

    return (
        <div id="playlist">
            <div className="songContainer">
                <h2>Klicka för att lyssna!</h2>
                <img className="speaker" src={speaker} /><p className="songTitle" onClick={clickedSong} >Förlorad</p>

            </div>
            
        </div>
    )
}

export default Playlist
