import React from 'react'
import song from ' ../../../public/IngePardon.mp3'

function Player(props) {
    return (
            <audio id="player" controls>
                <source src={song} type="audio/mpeg"/>
            </audio>
    )
}

export default Player
