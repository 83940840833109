import React, {useState} from 'react'
import './App.css';
import NavBar from './components/navbar/NavBar';
import Player from './components/player/Player';
import Playlist from './components/playlist/Playlist';


function App() {
  const [song, setSong] = useState(null);

  function setSongToPlay(songName) {
    console.log("setsong")
    setSong("IngePardon.mp3")
  }

  return (
    <div className="App">
      <NavBar/>
      <Playlist setSongToPlay={setSongToPlay} />
      <Player song={song} />
    </div>
  );
}

export default App;
